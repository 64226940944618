<script>
/**
 * Preloader component for EmailSearchForm
 */
export default {
  data() {
    return {};
  },
  props: {
    show: Boolean,
  },
  mounted() {},
};
</script>

<template>
  <div class="preloader-container">
    <div class="preloader-overlay" v-if="show"></div>
    <div class="sidebar" :class="{ minimized: !show }">
      <div v-if="show" :class="{ 'is-loading': false }">
        <div class="preloader-component">
          <div class="status">
            <div class="spinner-chase">
              <div class="chase-dot"></div>
              <div class="chase-dot"></div>
              <div class="chase-dot"></div>
              <div class="chase-dot"></div>
              <div class="chase-dot"></div>
              <div class="chase-dot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.preloader-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.733
  ); /* Ajusta el fondo de la pantalla */
  z-index: 1;
}

.preloader-component {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 20vh; /* Ajusta el valor para centrarlo verticalmente */
}
</style>