<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="tableComponent" class="table mb-0">
                            <thead>
                                <tr>
                                    <template v-for="header in headers" :key="header.index">
                                        <th :key="header.index" class="text-primary" v-if="header.visible">
                                            {{ header.label }}
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in logData" :key="item.id">
                                    <td v-for="header in headers" :key="header.index">{{ item[header.index] }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-d-component v-if="showTablePaginationFooter" class="col"
                            :collection-size="pagination.collectionSize" :current-page="pagination.page"
                            :lst-numeros="pagination.listaPageSize" :actual-show="pagination.actualShow"
                            @prev="page($event)" @next="page($event)" @onpage="page($event)"
                            @page-size-change="page($event)"></pagination-d-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationDComponent from '../PaginationComponent/PaginationD.component';
export default {
    components: {
        PaginationDComponent,
    },
    name: "TableMailComponent",
    props: {
        headers: {
            type: Object,
            required: true,
        },
        logData: {       // Propiedades que recibe el componente
            type: Object,  // Object, Array, String, Number, Boolean
            required: true, // Requerido
        },
        callbackSearch: {
            default: undefined
        },
        showTablePaginationFooter: {
            type: Boolean,
            default: true
        },
        pagination: {
            columnName: '', //change this for anothe property so it can change
            sortOrder: 'ASC',
            rfc: '',
            page: 1,                //currentPage
            listaPageSize: [10, 20, 40, 100],
            pageSize: 30,//page size de la primera vez, tiene que coincidide con 
            actualShow: 0,
            collectionSize: 10, //tamaño del resultado,
            range: {
                start: new Date(2019, 1, 16), //  
                end: new Date()    // desde ahora
            },
            showPageSize: false
        },
        showHideColumnsControl: {
            type: Boolean,
            default: false,
            note: "I added this feacture to allow show and hide column, a new control was added, by default this prop is false since it's in use and keep compatibility"
        },
    },
    data() {
        return {
        };
    },
    computed: {
        rows() {
            return this.items.length
        }
    },
    methods: {
        page(pagination) {
            // console.log("page :" + pagination.currentPage);
            // console.log(pagination);
            // eslint-disable-next-line
            this.pagination.page = pagination.currentPage;
            // eslint-disable-next-line
            this.pagination.pageSize = pagination.pageSize;
            if (this.callbackSearch) {
                this.callbackSearch(this.pagination);
            }

        },
        headersUpdated(event) {
            // eslint-disable-next-line
            this.headers = event.headers;
            if (this.pagination.columnName != event.selectedHeader.index
                || this.pagination.sortOrder != event.selectedHeader.order) {
                // eslint-disable-next-line
                this.pagination.columnName = event.selectedHeader.index;
                // eslint-disable-next-line
                this.pagination.sortOrder = event.selectedHeader.order;
                if (this.callbackSearch) {
                    this.callbackSearch(this.pagination);
                }
            }
        },
        search(optionalParamters) {
            if (this.callbackSearch) {
                // eslint-disable-next-line no-unused-vars
                let pagination = {};
                if (optionalParamters) {
                    pagination = this.pagination;
                    pagination = Object.assign(pagination, optionalParamters);
                }
                this.callbackSearch(this.pagination);
            }
        }
    }
};
</script>

    




