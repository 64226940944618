<template>
    <div class=" row ">
        <div aria-label="" class="col-md-6">

            <ul class="pagination">
                <li class="page-item" :disabled="currentPage <= 1">
                    <a class="page-link" @click="firts()" data-toggle="tooltip" data-placement="bottom" title="Primera">
                        <i class="fa fa-angle-double-left"></i>
                    </a>
                </li>
                <li class="page-item" :disabled="currentPage <= 1" data-toggle="tooltip" data-placement="bottom"
                    title="Anterior">
                    <a class="page-link" @click="prev()">
                        <i class="fa fa-angle-left"></i>
                    </a>
                </li>
                <li class="page-item" v-for="pagina in obtenerNumeros" :key="pagina" @click="changePage(pagina)"
                    v-bind:class="classPageItem(pagina)"><a class="page-link">{{ pagina }}</a></li>

                <li class="page-item "><a class="page-link  " @click="next()" data-toggle="tooltip" data-placement="bottom"
                        title="Siguiente">
                        <i class="fa fa-angle-right"></i>
                    </a></li>
                <li class="page-item "><a class="page-link  " @click="last()" data-toggle="tooltip" data-placement="bottom"
                        title="última">
                        <i class="fa fa-angle-double-right"></i>
                    </a></li>
            </ul>

        </div>
        <div class="col-md-4 mt-2">
            <!--
        <p> Mostrando :  {{(pageSize*currentPage)-pageSize+1}} a {{pageSize*currentPage}} de {{collectionSize}} registros</p>
         <p> Mostrando :  {{(pageSize*currentPage)-pageSize+1}} a {{actualShow}} de {{collectionSize}} registros</p>
        -->
            <p> Mostrando : {{ (pageSize * currentPage) - pageSize + 1 }} a {{ (pageSize * currentPage) - pageSize + actualShow }} de
                {{ collectionSize }} registros</p>
        </div>
        <div v-if="showPageSize" class="col-md-2">
            <select class="form-control  " v-model="pageSize" @change="changePageSize()">
                <option :value="numero" v-for="numero in lstNumeros" :key="numero">{{ numero }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "pagination-d-component",
    //template: '#PaginationD',
    props: [
        'collectionSize', //tamaño del resultado, si este valor no es establecido, entonces no se activaran los eventos correctamente
        'maxSize', //maximo del numero de pag 1,2,3,4,5....
        // '',//numero que se presenta en la tabla
        'currentPage',
        'lstNumeros', //lista pageSize disponibles
        'actualShow', //numero real de items mostrados que es igual o menor al pagesize
        'showPageSize'
    ],
    data: function () {
        //var lista=
        var pageSize = 1;
        if (this.lstNumeros && this.lstNumeros.length > 0) {
            pageSize = this.lstNumeros[2];
        }

        return {
            pageSize: pageSize
            //    msjOk:undefined
        };
    },
    computed: {
        obtenerNumeros: function () {
            //console.log("obtenerNumeros()");
            //console.log("pagesize" + this.pageSize);
            if (this.collectionSize && this.pageSize) {//divimos el total del la coleccion entre el tamaño de la pagina para obtene rle numero de paginas
                const max = Math.ceil(this.collectionSize / this.pageSize);
                console.log("numero de paginas:" + max);
                //segun mis pruebas 6 items que actuen como paginas es el maximo., esto numero varia ya que si las paginas superan los 2 digitos ocupan mas espacio y ya no se hace mantenible renderizarlas.
                var maxRealPagesNumber = max; //inicialmente seran iguales 
                const LimitPagesNumericButtons = 6;
                // const arr=Array.from(Array(max).keys());
                //console.log(max);

                if (max < 0) {
                    return [];
                }
                if (max > LimitPagesNumericButtons) {
                    maxRealPagesNumber = LimitPagesNumericButtons;//si excede las 7 paginas lo limito a las primeras 7 paginas
                }
                var starPageButton = 1; //siempre sera uno , a menos que la pagina actual exceda el numero 7 (LimitPagesNumericButtons)
                if (this.currentPage >= LimitPagesNumericButtons) { //solo cuando la pagina actual sea igual o mayor al maximo es cuando necesiamos hacer la rotacion(prque ya no habria boton numerico secuencial)
                    console.log('ultima pagina visible')
                    if (this.currentPage < max) { //verificamos que la pagina actual aun sea menor a max(es decir que aun se resten paginas para agregar como boton si no no tiene caso agregar el limte)
                        maxRealPagesNumber = this.currentPage + 1;   //para saber cual sera el ultimo boton sera la pagina actual +1, esto agregara solo un boton adicional
                        starPageButton = (this.currentPage - LimitPagesNumericButtons) + 2;//se le garega 2 para compensar el desplazamiento
                    } else if (this.currentPage == max) {//si ambos son iguales, es decir la pagina actual es igual a la ultima pagina posible entonces:
                        maxRealPagesNumber = this.currentPage;
                        starPageButton = (this.currentPage - LimitPagesNumericButtons) + 1;
                    }

                }

                const arr = this.range(starPageButton, maxRealPagesNumber);
                //console.log(arr);
                return arr;
            }
            return [];
        },
        pageSizesss() {
            //este era pageSize pero como solo nos importan cambiar esto una vez(la primera,
            // es vliado ponerlo como una propidad)
            if (this.lstNumeros && this.lstNumeros.length > 0) {
                return this.lstNumeros[0];
            }
            return 1;
        }
    },
    methods: {
        range(start, end) {
            if (Array.keys)
                //la funcion  Array.keys() no es comaptible con ie
                return [...Array(1 + end - start).keys()].map(v => start + v)
            else {
                var arr = [];
                for (var i = start; i <= end; i++) {
                    arr.push(i);
                }
                return arr;
            }
        },
        next() {
            if (this.collectionSize && this.pageSize) {
                const max = Math.ceil(this.collectionSize / this.pageSize);
                var next = this.currentPage + 1;
                console.log('next');
                console.log(this.currentPage);
                console.log(next);

                if (next > max) {
                    next = 1;
                }


                var pagination = {
                    pageSize: this.pageSize,
                    currentPage: next,

                };
                this.$emit('next', pagination);
                //this.$emit('next',next);

            }

        },
        prev() {
            if (this.collectionSize && this.pageSize) {
                const max = Math.ceil(this.collectionSize / this.pageSize);
                var prev = this.currentPage - 1;
                console.log('prev');
                console.log(prev);

                if (prev <= 0) {
                    prev = max;
                }


                var pagination = {
                    pageSize: this.pageSize,
                    currentPage: prev,
                };
                //this.$emit('prev',prev);
                this.$emit('prev', pagination);
            }
        },
        firts() {
            if (this.collectionSize && this.pageSize) {
                // eslint-disable-next-line no-unused-vars
                const max = Math.ceil(this.collectionSize / this.pageSize);
                var prev = 1;//siempre sera 1 
                var pagination = {
                    pageSize: this.pageSize,
                    currentPage: prev,

                };
                //this.$emit('prev',prev);
                this.$emit('prev', pagination);
            }
        }, last() {
            if (this.collectionSize && this.pageSize) {
                const max = Math.ceil(this.collectionSize / this.pageSize);

                var pagination = {
                    pageSize: this.pageSize,
                    currentPage: max,

                };
                this.$emit('next', pagination);
            }
        }
        ,
        changePage(page) {
            //this.currentPage=page;
            var pagination = {
                pageSize: this.pageSize,
                currentPage: page,

            };
            this.$emit('onpage', pagination)
        },
        changePageSize() {
            //debugger
            /*
            cuando cambia pagesize debe tambien 
            cambiar el current page a 1, ya que si estamos en un pagesize de 10 y luego cambiamos a uno
            de 20, y nos encontramos en la ultima pagina donde si hay resultados, esta pagina se mantendria y no mostraria
            mas elementos 
            */
            var pagination = {
                pageSize: this.pageSize,
                currentPage: 1,//debe ser uno ya que cambio el pageSize

            };
            this.$emit('pageSizeChange', pagination);
            this.$emit('page-size-change', pagination);
            //this
        },
        classPageItem(page) {
            return { 'active': page === this.currentPage ? true : false };
        }


    }
};
</script>